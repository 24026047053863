<template>
  <div class="sub-account">
    <div class="table-list-layout">
      <div class="table-list">
        <div class="header">
          <Breadcrumb>
            <BreadcrumbItem>{{ $t('zi-zhang-hao-guan-li') }}</BreadcrumbItem>
          </Breadcrumb>
        </div>
        <div class="content">
          <div class="option">
            <div class="left">
              <Select v-model="search.roleId" style="width: 120px;margin-right: 10px;" clearable>
                <Option v-for="option in roleList" :key="option.value" :value="option.value">
                  {{ option.name }}
                </Option>
              </Select>
              <Input v-model="search.userNameOrSubAccountPrefix"
                     :placeholder="$t('qing-shu-ru-xing-ming-zi-zhang-hao-uid-cha-xun')"
                     style="width: 280px;margin-right: 10px;" clearable/>
              <Button type="primary" @click="getSubAccountList">{{ $t('cha-xun') }}</Button>
            </div>
            <div class="right">
              <Button @click="handleClickAddBtn" type="primary" ghost style="margin-right: 10px;"
                      icon="md-add" v-if="showAddBtn">
                {{ $t('tian-jia-zi-zhang-hao') }}
              </Button>
              <Button @click="getSubAccountList">{{ $t('shua-xin') }}</Button>
            </div>
          </div>
          <div class="table">
            <Table :columns="subAccountColumns" :data="subAccountList"
                   :locale="{emptyText: $t('zan-wu-shu-ju')}" :loading="subAccountListLoading"
                   size="small" border>
              <template slot-scope="{ row }" slot="uid">
                <div class="uid">
                  {{row.uid}}
                  <cc-iconfont :size="12" name="copy" class="copy"
                               @click.native="copyText(`${row.uid}`, $t('fu-zhi-uid-cheng-gong'))"
                               style="margin-left: 3px;"/>
                </div>
              </template>
              <template slot="subAccount" slot-scope="{ row }">
                <div class="copy-account">
                  <div class="text">{{ row.subAccount }}</div>
                  <div class="square"/>
                  <cc-iconfont :size="12" name="copy"
                               @click.native="copyText(`${row.subAccount}`, $t('fu-zhi-zhang-hao-cheng-gong'))"
                               style="margin-left: 3px;"/>
                </div>
              </template>
              <template slot="status" slot-scope="{ row }">
                <cc-status :content="row.disable ? $t('ting-yong') : $t('qi-yong')"
                           :type="row.disable ? 'error':'success'"/>
              </template>
              <template slot="lastTryLoginTime" slot-scope="{ row }">
                <div>{{ row.lastTryLoginTime | formatTime('YYYY-MM-DD HH:mm:ss') }}</div>
              </template>
              <template slot="action" slot-scope="{ row }">
                <div class="action">
                  <Button v-if="myAuth.includes('RDP_AUTH_MANAGE')" :disabled="row.disable"
                            size="small"
                            type="text"
                            @click="goAuthPage('edit', row)">
                    {{ $t('shu-ju-ku-shou-quan') }}
                  </Button>
                  <Button v-if="myAuth.includes('RDP_USER_MANAGE')" size="small" type="text"
                            @click="handleTriggerStopAccount(row)">
                    {{ row.disable ? $t('qi-yong') : $t('ting-yong') }}
                  </Button>
                  <Poptip v-if="!forbidDelSubAccount && myAuth.includes('RDP_USER_MANAGE')"
                                :title="$t('que-ding-shan-chu-gai-zi-zhang-hao-ma')" transfer confirm
                                @on-ok="handleDeleteSubAccount(row)">
                    <Button type="text" size="small">{{ $t('shan-chu') }}</Button>
                  </Poptip>
                  <Dropdown transfer v-if="myAuth.includes('RDP_USER_MANAGE') || myAuth.includes('RDP_AUTH_READ')">
                    <a href="javascript:void(0)">
                      {{ $t("geng-duo") }}
                      <Icon type="ios-arrow-down"></Icon>
                    </a>
                    <template #list>
                      <DropdownMenu>
                        <DropdownItem v-if="myAuth.includes('RDP_AUTH_READ')">
                          <a @click="goAuthPage('view', row)">
                            {{ $t('yi-shou-quan-xian') }}
                          </a>
                        </DropdownItem>
                        <DropdownItem v-if="myAuth.includes('RDP_USER_MANAGE')">
                          <a v-if="row.bindType === 'INTERNAL'" @click="handleShowModifyAccount(row)">{{ $t('xiu-gai-zhang-hao') }}
                          </a>
                          <Tooltip :content="$t('bu-zhi-chi-ldap-yu-zhang-hao-he-mi-ma-de-xiu-gai-qing-lian-xi-ldap-yu-guan-li-yuan')" v-else transfer placement="left">
                            <a :disabled="row.bindType !== 'INTERNAL'" @click="handleShowModifyAccount(row)">{{ $t('xiu-gai-zhang-hao') }}
                            </a>
                          </Tooltip>
                        </DropdownItem>
                        <DropdownItem v-if="myAuth.includes('RDP_USER_MANAGE')">
                          <a v-if="row.bindType === 'INTERNAL'"
                            @click="handleShowEditPasswordModal(row)">{{ $t('chong-zhi-mi-ma') }}
                          </a>
                          <Tooltip :content="$t('bu-zhi-chi-ldap-yu-zhang-hao-he-mi-ma-de-xiu-gai-qing-lian-xi-ldap-yu-guan-li-yuan')" v-else transfer placement="left">
                            <a disabled="row.bindType !== 'INTERNAL'"
                               @click="handleShowEditPasswordModal(row)">{{ $t('chong-zhi-mi-ma') }}
                            </a>
                          </Tooltip>
                        </DropdownItem>
                        <DropdownItem v-if="myAuth.includes('RDP_USER_MANAGE')">
                          <a :disabled="row.disable"
                             @click="handleClickChangeRoleModal(row)">{{ $t('xiu-gai-jiao-se') }}
                          </a>
                        </DropdownItem>
                      </DropdownMenu>
                    </template>
                  </Dropdown>
                </div>
              </template>
            </Table>
          </div>
        </div>
      </div>
      <div class="footer">
        <Page :total="total" show-total show-elevator @on-change="handlePageChange"
              show-sizer
              v-model="pageNum"
              :page-size="pageSize" @on-page-size-change="handlePageSizeChange"/>
      </div>
    </div>
    <Modal v-model="showAddNewSubAccountModal" :mask-closable="false" :width="646"
           :title="$t('chuang-jian-zi-zhang-hao')" :closable="false">
      <Form ref="newAccountFormRef" :model="newAccountForm" :rules="newAccountRules" v-if="showAddNewSubAccountModal"
            :label-width="80">
        <FormItem :label="$t('xing-ming')" prop="userName">
          <Input v-model="newAccountForm.userName"/>
        </FormItem>
        <FormItem :label="$t('zi-zhang-hao')" prop="subAccount">
          <Input v-model="newAccountForm.subAccount">
            <div slot="append">
              @{{ userInfo.userDomain }}
            </div>
          </Input>
        </FormItem>
        <FormItem :label="$t('deng-lu-mi-ma')" prop="password">
          <div style="display: flex;width: 100%">
            <Input v-model="newAccountForm.password" :placeholder="passwordRule.tips"/>
            <Button style="margin-left: 10px;" @click="generateRandomPwd" type="primary" ghost>
              {{ $t('zi-dong-sheng-cheng') }}
            </Button>
          </div>
        </FormItem>
        <FormItem :label="$t('jiao-se')" prop="roleId">
          <div style="display: flex;width: 100%">
            <Select v-model="newAccountForm.roleId" :placeholder="$t('qing-xuan-ze')"
                    style="margin-right: 10px;">
              <Option v-for="role in roleList" :key="role.value" :value="role.value">
                {{ role.name }}
              </Option>
            </Select>
            <Button type="text" to="/system/role">{{ $t('chuang-jian-jiao-se') }}</Button>
          </div>
        </FormItem>
        <h4>{{ $t('tong-zhi-jie-shou-she-zhi') }}</h4>
        <FormItem :label="$t('shou-ji-hao')" prop="phone">
          <Input v-model="newAccountForm.phone"/>
        </FormItem>
        <FormItem :label="$t('you-xiang')" prop="email">
          <Input v-model="newAccountForm.email"/>
        </FormItem>
      </Form>
      <div slot="footer">
        <Button @click="handleCloseModal">{{ $t('qu-xiao') }}</Button>
        <Button type="primary" @click="handleAddSubAccount">{{ $t('chuang-jian') }}</Button>
      </div>
    </Modal>
    <Modal v-model="showChangeRoleModal" :width="320" :title="$t('xiu-gai-jiao-se')" @on-cancel="handleCloseModal">
      <Select v-model="newAccountForm.roleId" style="width: 276px;">
        <Option v-for="role in roleList" :key="role.value" :value="role.value">
          {{ role.name }}
        </Option>
      </Select>
      <div slot="footer">
        <Button @click="handleCloseModal">{{ $t('qu-xiao') }}</Button>
        <Button type="primary" @click="handleChangeUserRole">{{ $t('que-ding') }}</Button>
      </div>
    </Modal>
    <Modal v-model="showModifySubAccount" :width="560" :title="$t('xiu-gai-zhang-hao')" @on-cancel="handleCloseModal">
      <Form ref="newAccountFormRef" :model="newAccountForm" :label-width="80">
        <FormItem :label="$t('xing-ming')" prop="userName">
          <Input v-model="newAccountForm.userName" />
        </FormItem>
        <FormItem :label="$t('zi-zhang-hao')" prop="subAccount">
          <Input v-model="newAccountForm.accountName">
            <div slot="append">
              @{{ newAccountForm.userDomain }}
            </div>
          </Input>
        </FormItem>
      </Form>
      <div slot="footer">
        <Button @click="handleCloseModal">{{ $t('qu-xiao') }}</Button>
        <Button type="primary" @click="handleModifySubAccount">{{ $t('que-ding') }}</Button>
      </div>
    </Modal>
    <Modal
      v-model="showEditPasswordModal"
      :title="$t('chong-zhi-de-mi-ma', [newAccountForm.username])"
      width="500"
      :closable="false"
      :mask-closable="false"
    >
        <div style="margin-bottom: 10px;" v-if="editPasswordErrMsg">
          <Alert type="error">{{editPasswordErrMsg}}</Alert>
        </div>
        <Form :label-width="80" ref="passwordConfirmForm">
          <FormItem :label="$t('cao-zuo-ren-mi-ma')">
            <Input type="password" password v-model="opUserPassword" :placeholder="$t('xu-yan-zheng-cao-zuo-ren-shen-fen')"></Input>
          </FormItem>
          <FormItem :label="$t('xin-mi-ma')">
            <Poptip trigger="focus" placement="right-start" transfer style="width: 100%">
              <div style="display: flex">
                <Input v-model="newPassword" type="password" password style="width: 298px"
                       :placeholder="$t('qing-shu-ru-zi-zhang-hao-de-xin-mi-ma')"></Input>
                <Button style="margin-left: 10px;" @click="generateRandomPwd" type="primary" ghost>
                  {{ $t('zi-dong-sheng-cheng') }}
                </Button>
              </div>
              <div slot="content">
                <p>{{ passwordRule.tips }}</p>
              </div>
            </Poptip>
          </FormItem>
        </Form>
        <div slot="footer">
          <Button @click="handleCloseModal">{{ $t('guan-bi') }}</Button>
          <Button type="primary" @click="handleConfirmEditPassword">
            {{$t('que-ding') }}
          </Button>
        </div>
    </Modal>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { generateData } from '@/utils';
import { getSubAccountPwdValidateExpr } from '@/services/cc/api/rdpUser';
import copyMixin from '@/mixins/copyMixin';
import enterOpPwdMixin from '@/mixins/modal/enterOpPwdMixin';
import { encryptMixin } from '@/mixins/encryptMixin';
import RandExp from 'randexp';
import deepClone from 'lodash.clonedeep';

const EMPTY_SUB_ACCOUNT = {
  userName: '',
  subAccount: '',
  password: '',
  roleId: undefined,
  phone: '',
  email: ''
};

// 数据和方法带2的都是数据库管理权限相关
export default {
  name: 'SubAccount',
  mixins: [copyMixin, enterOpPwdMixin, encryptMixin],
  computed: {
    ...mapState(['userInfo', 'globalSetting', 'myCatLog', 'myAuth']),
    showAddBtn() {
      let canAddSubAccount = true;
      if (this.userInfo && this.userInfo.userConfig) {
        this.userInfo.userConfig.forEach((config) => {
          if (config.configName === 'subAccountAuthType' && config.configValue !== 'PASSWORD') {
            canAddSubAccount = false;
          }
        });
      }
      return canAddSubAccount && this.myAuth.includes('RDP_USER_MANAGE');
    }
  },
  data() {
    return {
      newAccountForm: deepClone(EMPTY_SUB_ACCOUNT),
      newAccountRules: {},
      search: {
        roleId: '',
        userNameOrSubAccountPrefix: ''
      },
      subAccountColumns: [
        {
          title: this.$t('xing-ming'),
          key: 'username',
          width: 200
        },
        {
          title: this.$t('zhang-hao'),
          slot: 'subAccount',
          minWidth: 300
        },
        {
          title: 'uid',
          slot: 'uid',
          minWidth: 200
        },
        {
          title: this.$t('jiao-se'),
          key: 'roleName',
          width: 100
        },
        {
          title: this.$t('zhuang-tai'),
          slot: 'status',
          width: 100
        },
        {
          title: this.$t('shang-ci-deng-lu-shi-jian'),
          slot: 'lastTryLoginTime',
          width: 200
        },
        {
          title: this.$t('bang-ding-de-zhang-hao'),
          key: 'bindAccount',
          width: 200
        },
        {
          title: this.$t('zhang-hao-lei-xing'),
          key: 'bindType',
          width: 200
        },
        {
          title: this.$t('cao-zuo'),
          slot: 'action',
          fixed: 'right',
          width: 260
        }
      ],
      subAccountListLoading: false,
      showEditPasswordModal: false,
      opUserPassword: '',
      newPassword: '',
      editPasswordErrMsg: '',
      showChangeRoleModal: false,
      showModifySubAccount: false,
      showAddNewSubAccountModal: false,
      showManageRoleModal: false,
      selectedSubaccount: {
        name: '',
        account: '',
        role: '',
        status: '',
        createTime: ''
      },
      total: 0,
      pageSize: 20,
      pageNum: 1,
      subAccountList: [],
      showSubAccountList: [],
      roleList: [],
      forbidDelSubAccount: false,
      passwordRule: {
        expr: '',
        tips: ''
      }
    };
  },
  mounted() {
    this.userInfo.userConfig.forEach((config) => {
      if (config.configName === 'forbidDelSubAccount' && config.configValue) {
        this.forbidDelSubAccount = JSON.parse(config.configValue);
      }
    });
  },
  methods: {
    async handleModifySubAccount() {
      const {
        uid,
        userDomain
      } = this.newAccountForm;
      const data = {
        targetUid: uid,
        userName: this.newAccountForm.userName,
        subAccount: `${this.newAccountForm.accountName}@${userDomain}`
      };

      const res = await this.$services.updateSubAccount({
        data
      });

      if (res.success) {
        this.$Message.success(this.$t('xiu-gai-zhang-hao-cheng-gong'));
        await this.getSubAccountList();
        this.handleCloseModal();
      }
    },
    async handleChangeUserRole() {
      const {
        parentId,
        uid,
        roleId
      } = this.newAccountForm;
      const data = {
        parentId,
        subAccountUid: uid,
        roleId
      };

      const res = await this.$services.updateUserRole({
        data
      });

      if (res.success) {
        this.$Message.success(this.$t('geng-xin-jiao-se-cheng-gong'));
        await this.getSubAccountList();
        this.handleCloseModal();
      }
    },
    generateRandomPwd() {
      const regex = new RegExp(this.passwordRule.expr);
      const randexp = new RandExp(regex);
      const password = randexp.gen();
      if (regex.test(password)) {
        this.newAccountForm.password = password;
        this.newPassword = password;
        if (this.$refs.newAccountFormRef && this.showAddNewSubAccountModal) {
          this.$refs.newAccountFormRef.validateField('password');
        }
      } else {
        this.generateRandomPwd();
      }
    },
    async handleAddSubAccount() {
      console.log('add sub account');
      this.$refs.newAccountFormRef.validate(async (valid) => {
        if (valid) {
          const res = await this.$services.addSubAccount({
            data: {
              ...this.newAccountForm,
              subAccount: `${this.newAccountForm.subAccount}@${this.userInfo.userDomain}`,
              password: this.passwordEncrypt(this.newAccountForm.password)
            }
          });

          if (res.success) {
            this.$Message.success(this.$t('chuang-jian-zi-zhang-hao-cheng-gong'));
            await this.getSubAccountList();
            this.handleCloseModal();
          }
        }
      });
    },
    goAuthPage(type, record) {
      this.$router.push({ path: `sub_account/auth/${record.uid}?name=${record.subAccount}&&type=${type}` });
    },
    async handleDeleteSubAccount(subAccount) {
      const res = await this.$services.deleteSubAccount({
        data: { subAccount: subAccount.subAccount },
        msg: this.$t('shan-chu-zi-zhang-hao-cheng-gong')
      });

      if (res.success) {
        await this.getSubAccountList();
      }
    },
    handleShowModifyAccount(row) {
      this.newAccountForm = deepClone(row);
      this.newAccountForm.userName = row.username;
      this.newAccountForm.accountName = row.subAccount.split('@')[0];
      this.showModifySubAccount = true;
    },
    handleShowEditPasswordModal(row) {
      this.newAccountForm = deepClone(row);
      this.showEditPasswordModal = true;
    },
    async handleClickChangeRoleModal(row) {
      await this.getRoleList();
      this.newAccountForm = deepClone(row);
      this.showChangeRoleModal = true;
    },
    handleCloseModal() {
      this.newAccountForm = deepClone(EMPTY_SUB_ACCOUNT);
      this.showAddNewSubAccountModal = false;
      this.showChangeRoleModal = false;
      this.showModifySubAccount = false;
      this.showEditPasswordModal = false;
      this.opUserPassword = '';
      this.newPassword = '';
      this.editPasswordErrMsg = '';
    },
    async handleConfirmEditPassword() {
      const regExp = new RegExp(this.passwordRule.expr);
      if (regExp.test(this.newPassword)) {
        const res = await this.$services.resetSubAccountPwd({
          data: {
            operatorPwd: this.passwordEncrypt(this.opUserPassword),
            newPassword: this.passwordEncrypt(this.newPassword),
            subAccountUid: this.newAccountForm.uid
          },
          modal: false
        });
        if (res.success) {
          this.$Message.success(this.$t('zi-zhang-hao-mi-ma-chong-zhi-cheng-gong'));
          this.handleCloseModal();
        } else {
          this.editPasswordErrMsg = res.msg;
        }
      } else {
        console.log('reset error');
        this.editPasswordErrMsg = this.passwordRule.tips;
      }
    },
    handlePageChange(pageNum) {
      this.pageNum = pageNum;
      this.setTableShowData();
    },
    handlePageSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.handlePageChange(1);
    },
    setTableShowData() {
      const { pageNum, pageSize } = this;
      this.showSubAccountList = this.subAccountList.slice((pageNum - 1) * pageSize, pageNum * pageSize);
    },
    async getSubAccountList() {
      this.subAccountListLoading = true;
      const res = await this.$services.getSubAccountList({ data: this.search });
      this.subAccountListLoading = false;
      if (res.success) {
        this.subAccountList = generateData(res.data);
        this.total = this.subAccountList.length;
        this.setTableShowData();
      }
    },
    async getRoleList() {
      const roleListRes = await this.$services.listRules();
      if (roleListRes.success) {
        const roleList = [];
        roleListRes.data.forEach((role) => {
          roleList.push({
            name: role.aliasName,
            value: role.roleId
          });
        });
        this.roleList = roleList;
      }
    },
    async handleClickAddBtn() {
      await this.getRoleList();
      this.showAddNewSubAccountModal = true;
    },
    async handleTriggerStopAccount(row) {
      const {
        disable,
        uid
      } = row;
      const data = {
        disable: !disable,
        uid
      };
      const res = await this.$services.stopSubAccount({
        data,
        msg: disable ? this.$t('qi-yong-cheng-gong') : this.$t('ting-yong-cheng-gong')
      });
      if (res.success) {
        await this.getSubAccountList();
        // Vue.set(this.accountList[index], 'disable', !disable);
      }
    }
  },
  created() {
    this.getSubAccountList();
    this.getRoleList();
    getSubAccountPwdValidateExpr().then((res) => {
      if (res.data.success) {
        this.passwordRule.expr = res.data.data.expr;
        this.passwordRule.tips = res.data.data.tips;
        const validateSubAccount = async (checkType, checkContent, callback, msg) => {
          const res2 = await this.$services.checkSubAccountDuplicate({
            data: {
              checkType,
              checkContent
            },
            modal: false
          });
          if (res2.success) {
            callback();
          } else {
            callback(new Error(this.$t('msg-zhong-fu', [msg])));
          }
        };
        const validateSubAccountName = (rule, value, callback) => {
          validateSubAccount('SUB_ACCOUNT', value, callback, this.$t('zi-zhang-hao'));
        };
        const validateSubAccountPhone = (rule, value, callback) => {
          validateSubAccount('PHONE', value, callback, this.$t('shou-ji-hao'));
        };
        const validateSubAccountEmail = (rule, value, callback) => {
          validateSubAccount('EMAIL', value, callback, this.$t('you-xiang'));
        };
        this.newAccountRules = {
          userName: [
            {
              required: true,
              trigger: 'blur',
              message: this.$t('xing-ming-bu-neng-wei-kong')
            }
          ],
          subAccount: [
            {
              required: true,
              trigger: 'blur',
              message: this.$t('zi-zhang-hao-bu-neng-wei-kong')
            }, {
              validator: validateSubAccountName,
              trigger: 'blur'
            }
          ],
          password: [
            {
              required: true,
              trigger: 'blur',
              message: this.$t('mi-ma-bu-neng-wei-kong')
            }, {
              pattern: new RegExp(this.passwordRule.expr),
              message: this.passwordRule.tips
            }
          ],
          roleId: [
            {
              required: true,
              trigger: 'blur',
              message: this.$t('jiao-se-bu-neng-wei-kong'),
              transform: (value) => String(value)
            }
          ],
          phone: [
            {
              required: true,
              trigger: 'blur',
              message: this.$t('shou-ji-hao-bu-neng-wei-kong')
            }, {
              validator: validateSubAccountPhone,
              trigger: 'blur'
            }
          ],
          email: [
            {
              required: true,
              trigger: 'blur',
              message: this.$t('you-xiang-bu-neng-wei-kong')
            }, {
              validator: validateSubAccountEmail,
              trigger: 'blur'
            }
          ]
        };
      }
    });
  }
};
</script>

<style lang="less" scoped>

.sub-account {
  display: flex;
  flex-direction: column;
  height: 100%;

  .uid {
    display: flex;
    cursor: pointer;

    .copy {
      display: none;
    }

    &:hover {
      .copy {
        display: block;
      }
    }
  }

  .copy-account {
    display: flex;
    align-items: center;
    cursor: pointer;

    .square {
      width: 15px;
      height: 12px;
    }

    i {
      display: none;
    }

    &:hover {
      i {
        display: block;
      }

      .square {
        display: none;
      }
    }
  }

  .action {
    //button {
    //  margin-right: 12px;
    //}
    //.ivu-dropdown {
    //  padding: 0 7px;
    //}
  }

  .actions {
    font-size: 12px;
  }
}

.manage-role-modal {
  display: flex;

  .left {
    .title {
      margin-bottom: 10px;

      span {
        color: #888;

        &:first-child {
          color: #333;
          font-weight: bold;
          margin-right: 10px;
        }
      }
    }

    .role-table {
      display: flex;
      flex-direction: column;
      height: 400px;
      border: 1px solid rgba(234, 234, 234, 1);
    }
  }

  .new-role {
    flex: 1;
    padding: 20px;
  }
}

.new-subaccount-modal {
  .ivu-input-wrapper {
    width: 420px;
  }

  .title {
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 18px;
  }
}
</style>
